import React from 'react';
import {
  AppLayout,
  AuthRoutes,
} from './@leo';
import JWTAuthAuthProvider from './@leo/services/auth/jwt-auth/JWTAuthProvider';
import { BrowserRouter } from 'react-router-dom';
import AppLocaleProvider from './@leo/utility/AppLocaleProvider';
import AppThemeProvider from './@leo/utility/AppThemeProvider';
import AppContextProvider from './@leo/utility/AppContextProvider';
import { CookiesProvider, useCookies } from 'react-cookie';
import jwtAxios from './@leo/services/auth/jwt-auth/jwt-api';
// import AppReduxProvider from '@leo/utility/AppReduxProvider';
const axiosInterceptor = (removeCookie: (name: "token" | "refreshToken", options?: any | undefined) => void) => {
  jwtAxios.interceptors.response.use(
    (res) => res,
    (err) => {
      if (err?.response && (err?.response?.status === 401 || err?.response?.status === 403)) {
        console.log('Need to logout user');
        removeCookie('token', { path: '/' });
        removeCookie('refreshToken', { path: '/' });
        delete jwtAxios.defaults.headers.common.Authorization;
        window.location.reload();
        // store.dispatch({type: LOGOUT});
      }
      return Promise.reject(err);
    },
  );
}

const App = () => {
  const [_, __, removeCookie] = useCookies(['token', 'refreshToken']);

  axiosInterceptor(removeCookie)
  return (
    <AppContextProvider>
      <CookiesProvider defaultSetOptions={{ path: '/' }}>
        <AppThemeProvider>
          <AppLocaleProvider>
            <BrowserRouter>
              <React.Fragment>
                <JWTAuthAuthProvider>
                  <AuthRoutes>
                    <AppLayout />
                  </AuthRoutes>
                </JWTAuthAuthProvider>
              </React.Fragment>
            </BrowserRouter>
          </AppLocaleProvider>
        </AppThemeProvider>
      </CookiesProvider>
    </AppContextProvider>
  );
}

export default App;
