import React from 'react';

export const notificationsPagesConfigs = [
  {
    path: '/notification/information',
    component: React.lazy(() => import('./information')),
  },
  {
    path: '/notification/condition',
    component: React.lazy(() => import('./condition')),
  },
  {
    path: '/notification/statistical',
    component: React.lazy(() => import('./statistical')),
  },
];
