import React from "react";
import { authRouteConfig } from "./auth";
import { initialUrl } from "../shared/constants/AppConst";
import Error403 from "./errorPages/Error403";
import { errorPagesConfigs } from "./errorPages";
import { statisticalPagesConfigs } from "./statistical";
import { giftManagerPagesConfigs } from "./gift-manager";
import { storeManagerPagesConfigs } from "./store-manager";
import { gameManagerPagesConfigs } from "./game-manager";
import { notificationsPagesConfigs } from "./notifications";
import { accountsPagesConfigs } from "./accounts";
import { systemPagesConfigs } from "./system";

const authorizedStructure = {
  fallbackPath: "/signin",
  unAuthorizedComponent: <Error403 />,
  routes: [
    ...statisticalPagesConfigs,
    ...giftManagerPagesConfigs,
    ...storeManagerPagesConfigs,
    ...gameManagerPagesConfigs,
    ...notificationsPagesConfigs,
    ...accountsPagesConfigs,
    ...systemPagesConfigs,
  ],
};

const unAuthorizedStructure = {
  fallbackPath: initialUrl,
  routes: authRouteConfig,
};

const anonymousStructure = {
  routes: errorPagesConfigs,
};

export { authorizedStructure, unAuthorizedStructure, anonymousStructure };
