import React from "react";
import "./index.style.less";
import { useSidebarContext } from "../../../../utility/AppContextProvider/SidebarContextProvider";
import IntlMessages from "../../../../utility/IntlMessages";
import Logo from '../../../../../assets/icon/logo-white.png'
import { useNavigate } from "react-router-dom";
import clsx from "clsx";

interface IAppLogo {
  hasSidebarColor?: boolean;
  isCollapsed: boolean;
}

const AppLogo: React.FC<IAppLogo> = ({ hasSidebarColor, isCollapsed }) => {
  const { sidebarColorSet } = useSidebarContext();
  const navigate = useNavigate();
  const handleBackHome = () => {
    navigate("/");
  };
  return (
    <div className={clsx("cr-user-info")}>
      <div
        className={clsx("app-logo", {
          "logo-disable": isCollapsed,
        })}
      >
        {hasSidebarColor && sidebarColorSet.mode === "dark" ? (
          <div className="logo-flex">
            {/* <p className="logo-title">
              <IntlMessages id="common.wrapper.title.logo" />
            </p> */}
            <img src={'/static/icons/logo-white.svg'} alt='logo' style={{ height: 40, width: 'auto' }} />
          </div>
        ) : (
          <div className="logo-flex" onClick={handleBackHome}>
            {/* <p className="logo-title">
              <IntlMessages id="common.wrapper.title.logo" />
            </p> */}
            <img src={'/static/icons/logo-white.svg'} alt='logo' style={{ height: 40, width: 'auto' }} />
          </div>
        )}
      </div>
    </div>
  );
};

export default AppLogo;
