export const ERR_DEFAULT = 'Đã có lỗi xảy ra, vui lòng thử lại!'
export const DEFAULT_OBJECT = {
  succeeded: false,
  message: "",
  data: null
};
export const DEFAULT_ARRAY = {
  succeeded: false,
  message: "",
  data: []
};

// E Store Role
export enum EStoreRole {
  ShopOwner='ShopOwner',
  ShopSupporter='ShopSupporter',
  ShopOperator='ShopOperator'
}

export interface IPageData {
  page: number;
  pageSize: number;
}

export interface INotesTemplateGameForm {
  id: string;
  value: string;
}