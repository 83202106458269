import React from "react";

export const gameManagerPagesConfigs = [
  {
    path: "/game-manager/game",
    component: React.lazy(() => import("./game")),
  },
  {
    path: "/game-manager/game/:id",
    component: React.lazy(() => import("./game/detail")),
  },
  {
    path: "/game-manager/template",
    component: React.lazy(() => import("./template")),
  },
  {
    path: "/game-manager/request",
    component: React.lazy(() => import("./request")),
  },
];
