import React from 'react';

export const giftManagerPagesConfigs = [
  {
    path: '/gift-manager/gift',
    component: React.lazy(() => import('./gift')),
  },
  {
    path: '/gift-manager/combo',
    component: React.lazy(() => import('./combo')),
  },
];
