import React from "react";

export const storeManagerPagesConfigs = [
  {
    path: "/store-manager/store-list",
    component: React.lazy(() => import("./store")),
  },
  {
    path: "/store-manager/distributor-list",
    component: React.lazy(() => import("./distributor")),
  },
];
