import React from 'react';
import { AiOutlineMenuFold, AiOutlineMenuUnfold } from "react-icons/ai";
import './index.style.less';
import AppScrollbar from '../../AppScrollbar';
import clsx from 'clsx';
import AppVerticalMenu from '../components/AppVerticalNav';
import { useSidebarContext } from '../../../utility/AppContextProvider/SidebarContextProvider';
import MainSidebar from '../components/MainSidebar';
import AppLogo from '../components/AppLogo';

interface IAppSidebar {
  isCollapsed: boolean;
  onToggleSidebar: () => void;
}

const AppSidebar: React.FC<IAppSidebar> = ({ isCollapsed, onToggleSidebar }) => {
  const { isSidebarBgImage } = useSidebarContext();

  return (
    <MainSidebar
      className={clsx('mini-sidebar-toggle', {
        'mini-sidebar-toggle-img-background': isSidebarBgImage,
      })}
      collapsible
      breakpoint='xl'
      collapsedWidth='0'
      collapsed={isCollapsed}>
      <AppLogo isCollapsed={isCollapsed} />
      <AppScrollbar className='app-mini-sidebar-scrollbar' scrollToTop={false}>
        <AppVerticalMenu isCollapsed={isCollapsed} />
        <div onClick={onToggleSidebar} style={{ background: 'linear-gradient(0deg, rgba(0, 0, 0, 0.10) 0%, rgba(0, 0, 0, 0.10) 100%), #0054A5', position: 'absolute', bottom: 50, left: 0, width: '100%', display: 'flex', justifyItems: 'center', cursor: 'pointer' }}>
          {React.createElement(
            isCollapsed ? AiOutlineMenuUnfold : AiOutlineMenuFold,
            {
              className: "trigger",
              onClick: onToggleSidebar,
              style: { color: 'white' }
            },
          )}
        </div>
      </AppScrollbar>
    </MainSidebar>
  );
};

export default AppSidebar;
