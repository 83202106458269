import React from "react";
import { Layout } from "antd";
import "./index.style.less";
import { AiOutlineMenuFold, AiOutlineMenuUnfold } from "react-icons/ai";
import UserInfo from "../components/UserInfo";

interface IAppHeader {
  isCollapsed: boolean;
  onToggleSidebar: () => void;
}

const AppHeader: React.FC<IAppHeader> = ({ isCollapsed, onToggleSidebar }) => {
  const { Header } = Layout;

  return (
    <Header className="app-header-mini-sidebar">
      {React.createElement(
        isCollapsed ? AiOutlineMenuUnfold : AiOutlineMenuFold,
        {
          className: "trigger",
          onClick: onToggleSidebar,
        },
      )}
      <div className="app-header-flex-right">
        <UserInfo hasColor isCollapsed={isCollapsed} />
      </div>
    </Header>
  );
};

export default AppHeader;
