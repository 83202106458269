import React from "react";

export const systemPagesConfigs = [
  {
    path: "/system/personal",
    component: React.lazy(() => import("./personal")),
  },
  {
    path: "/system/zns",
    component: React.lazy(() => import("./zns")),
  },
];
