import React, { createContext, ReactNode, useContext, useEffect, useState } from 'react';
import useCommonStore from '../../../../zustand/reducers/Common';
import jwtAxios, { setAuthToken } from './jwt-api';
import { ERR_DEFAULT } from '../../../../types/enums/BaseEnum';
import { appIntl } from '../../../utility/Utils';
import { useNavigate } from 'react-router-dom';
import { useCookies } from 'react-cookie';

const DEFAULT_USER = {
  id: 1,
  uid: '1',
  displayName: 'Admin',
  email: 'admin@gmail.com',
  photoURL: '',
  role: 'admin'
}

interface JWTAuthContextProps {
  user: any | null | undefined;
  isAuthenticated: boolean;
  isLoading: boolean;
  data: any[];
}

interface JWTAuthAuthProviderProps {
  children: ReactNode;
}

interface SignUpProps {
  name: string;
  email: string;
  password: string;
}

interface SignInProps {
  email: string;
  password: string;
}

interface ICreatePassword {
  session: string;
  password: string;
  email: string;
  confirmPassword: string;
}

interface JWTAuthActionsProps {
  signUpUser: (data: SignUpProps) => void;
  signInUser: (data: SignInProps) => void;
  forgotPassword: (account: string) => any;
  createPassword: (data: ICreatePassword) => any;
  logout: () => void;
}

const JWTAuthContext = createContext<JWTAuthContextProps>({
  user: null,
  isAuthenticated: false,
  isLoading: true,
  data: []
});
const JWTAuthActionsContext = createContext<JWTAuthActionsProps>({
  signUpUser: () => { },
  signInUser: () => { },
  forgotPassword: () => { },
  createPassword: () => { },
  logout: () => { },
});

export const useJWTAuth = () => useContext(JWTAuthContext);

export const useJWTAuthActions = () => useContext(JWTAuthActionsContext);

const JWTAuthAuthProvider: React.FC<JWTAuthAuthProviderProps> = ({
  children,
}) => {
  const navigate = useNavigate();
  const [firebaseData, setJWTAuthData] = useState<JWTAuthContextProps>({
    user: null,
    isAuthenticated: false,
    isLoading: true,
    data: []
  });
  const [cookies, setCookie, removeCookie] = useCookies(['token', 'refreshToken']);
  const { fetchStart, fetchSuccess, fetchError } = useCommonStore((state) => state)
  const { messages } = appIntl();

  const createPassword = async (dataInfo: ICreatePassword) => {
    fetchStart();
    try {
      const { data } = await jwtAxios.post(
        "/api/v1/accounts/GMS/setPassword",
        dataInfo,
      );
      if (data?.succeeded) {
        fetchSuccess();
      } else {
        fetchError(
          data?.message || String(messages["message.somethingWentWrong"]),
        );
      }
      return data;
    } catch (error: any) {
      fetchError(
        error?.message || String(messages["message.somethingWentWrong"]),
      );
      return null;
    }
  };

  useEffect(() => {
    const getAuthUser = async () => {
      const token = cookies.token;
      const refreshToken = cookies.refreshToken;
      console.log(token)
      console.log(refreshToken)
      const info = localStorage.getItem('info');

      if (!token || !refreshToken) {
        setJWTAuthData({
          user: info ? JSON.parse(info) : DEFAULT_USER,
          isLoading: false,
          isAuthenticated: false,
          data: []
        });
        return;
      } else {
        setAuthToken(token, refreshToken);
        setJWTAuthData({
          user: info ? JSON.parse(info) : DEFAULT_USER,
          isLoading: false,
          isAuthenticated: true,
          data: []
        });
        return;
      }
    };

    getAuthUser();
  }, []);

  const signInUser = async ({ email, password }: any) => {
    fetchStart();
    try {
      const { data } = await jwtAxios.post("/api/v1/accounts/GMS/login", { email, passWord: password });
      if (data.succeeded) {
        setAuthToken(data.data?.accessToken, data.data?.refreshToken);
        const dateStr = new Date(data.data.expiresAt).toISOString();
        setCookie('token', data.data?.accessToken, { path: '/', sameSite: 'strict', secure: true, expires: new Date(dateStr) });
        setCookie('refreshToken', data.data?.refreshToken, { path: '/', sameSite: 'strict', secure: true, expires: new Date(dateStr) });
        setJWTAuthData({
          user: { ...DEFAULT_USER, email: email },
          isAuthenticated: true,
          isLoading: false,
          data: []
        });
        fetchSuccess();
        navigate("/statistic");
      } else {
        setJWTAuthData({
          ...firebaseData,
          isAuthenticated: false,
          isLoading: false,
        });
        fetchError(data?.message || ERR_DEFAULT);
      }
    } catch (error: any) {
      setJWTAuthData({
        ...firebaseData,
        isAuthenticated: false,
        isLoading: false,
      });
      console.log(error);
      fetchError(error?.message || ERR_DEFAULT);
    }
  };

  const forgotPassword = async (account: string) => {
    fetchStart();
    try {
      const { data } = await jwtAxios.post(
        "/api/v1/accounts/GMS/forgotPassword",
        {
          email: account,
        },
      );
      if (data?.succeeded) {
        fetchSuccess();
      } else {
        fetchError(
          data?.message || String(messages["message.somethingWentWrong"]),
        );
      }
      return data;
    } catch (error: any) {
      fetchError(
        error?.message || String(messages["message.somethingWentWrong"]),
      );
      return null;
    }
  };

  const logout = async () => {
    setAuthToken();
    removeCookie('token', { path: '/' });
    removeCookie('refreshToken', { path: '/' });
    setJWTAuthData({
      user: null,
      isLoading: false,
      isAuthenticated: false,
      data: []
    });
  };
  const signUpUser = () => {
  }

  return (
    <JWTAuthContext.Provider
      value={{
        ...firebaseData,
      }}>
      <JWTAuthActionsContext.Provider
        value={{
          createPassword,
          forgotPassword,
          signUpUser,
          signInUser,
          logout,
        }}>
        {children}
      </JWTAuthActionsContext.Provider>
    </JWTAuthContext.Provider>
  );
};
export default JWTAuthAuthProvider;
