import React, { useState } from 'react';
import { Layout } from 'antd';
import AppSidebar from './AppSidebar';
import AppHeader from './AppHeader';
import './index.style.less';
import { AppContentView } from '../../../index';
import AppScrollbar from '../../AppScrollbar';
import clsx from 'clsx';
import { FooterType } from '../../../../shared/constants/AppEnums';
import { useLayoutContext } from '../../../utility/AppContextProvider/LayoutContextProvider';
import { useLocation } from 'react-router-dom';

const MiniSidebarToggle = () => {
  const location = useLocation();
  const [isCollapsed, setCollapsed] = useState(false);
  const { footer, footerType } = useLayoutContext();

  const onToggleSidebar = () => {
    setCollapsed(!isCollapsed);
  };
  return (
    <Layout
      className={clsx('app-layout-mini-sidebar', {
        appMainFooter: footer && footerType === FooterType.FLUID,
        appMainFixedFooter: footer && footerType === FooterType.FIXED,
      })}
      style={{
        paddingTop: location?.pathname === "/create-password" ? 0 : undefined,
      }}
    >
      {location?.pathname === "/create-password" ? (
        <AppContentView />
      ) : (
        <React.Fragment>
          <AppSidebar
            isCollapsed={isCollapsed}
            onToggleSidebar={onToggleSidebar}
          />
          <Layout className='app-layout-mini-sidebar-main'>
            <AppHeader
              isCollapsed={isCollapsed}
              onToggleSidebar={onToggleSidebar}
            />
            <AppScrollbar className='main-mini-sidebar-scrollbar'>
              <AppContentView />
            </AppScrollbar>
          </Layout>
        </React.Fragment>
      )}
    </Layout>
  );
};

export default MiniSidebarToggle;
