import { authRole } from "../../../shared/constants/AppConst";
import { ReactComponent as AvatarDefault } from "../../../assets/user/avatar-default.svg";

export const getUserFromJwtAuth = (user: any) => {
  if (user)
    return {
      id: 1,
      uid: 1,
      displayName: user?.name ? user.name : "Serati Ma",
      email: user?.email ? user.emai : "",
      photoURL: user?.photoURL ? (
        <img src={user.photoURL} alt="avatar" />
      ) : (
        <AvatarDefault />
      ),
      role: authRole.user,
      accountId: user?.accountId || 0,
      companyId: user?.companyId || 0,
      companyName: user?.companyName || "",
      fullName: user?.fullName || "",
      userName: user?.userName || "",
      roleId: user?.roleId || 0,
      userCode: user?.userCode || "",
    };
  return user;
};
