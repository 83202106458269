import React from "react";
import {
  DashboardOutlined,
  GiftOutlined,
  ShopOutlined,
  StarOutlined,
  NotificationOutlined,
  UserOutlined,
  SettingOutlined
} from "@ant-design/icons";
import IntlMessages from "../@leo/utility/IntlMessages";

const routesConfig = [
  {
    id: "statistic",
    title: <IntlMessages id="sidebar.statistic" />,
    messageId: "sidebar.statistic",
    icon: <DashboardOutlined />,
    type: "item",
    path: "/statistic",
    children: [],
  },
  {
    id: "game-manager",
    title: <IntlMessages id="sidebar.gameManager" />,
    messageId: "sidebar.gameManager",
    icon: <StarOutlined />,
    type: "collapse",
    path: "/game-manager",
    children: [
      {
        id: "game",
        title: <IntlMessages id="sidebar.gameManager.game" />,
        messageId: "sidebar.gameManager.game",
        type: "item",
        icon: <div style={{ marginLeft: -35, width: 1 }} />,
        path: "/game-manager/game",
      },
      {
        id: "game-id",
        title: <IntlMessages id="sidebar.gameManager.game" />,
        messageId: "sidebar.gameManager.game",
        type: "divider",
        icon: <div style={{ marginLeft: -35, width: 1 }} />,
        path: "/game-manager/game/:id",
      },
      {
        id: "template",
        title: <IntlMessages id="sidebar.gameManager.template" />,
        messageId: "sidebar.gameManager.template",
        type: "item",
        icon: <div style={{ marginLeft: -35, width: 1 }} />,
        path: "/game-manager/template",
      },
      {
        id: "request",
        title: <IntlMessages id="sidebar.gameManager.request" />,
        messageId: "sidebar.gameManager.request",
        type: "item",
        icon: <div style={{ marginLeft: -35, width: 1 }} />,
        path: "/game-manager/request",
      },
    ],
  },
  {
    id: "gift-manager",
    title: <IntlMessages id="sidebar.giftManager" />,
    messageId: "sidebar.giftManager",
    icon: <GiftOutlined />,
    type: "collapse",
    path: "/gift-manager",
    children: [
      {
        id: "gift",
        title: <IntlMessages id="sidebar.giftManager.gift" />,
        messageId: "sidebar.giftManager.gift",
        type: "item",
        icon: <div style={{ marginLeft: -35, width: 1 }} />,
        path: "/gift-manager/gift",
      },
      {
        id: "combo",
        title: <IntlMessages id="sidebar.giftManager.combo" />,
        messageId: "sidebar.giftManager.combo",
        type: "item",
        icon: <div style={{ marginLeft: -35, width: 1 }} />,
        path: "/gift-manager/combo",
      },
    ],
  },
  {
    id: "store-manager",
    title: <IntlMessages id="sidebar.storeManager" />,
    messageId: "sidebar.storeManager",
    icon: <ShopOutlined />,
    type: "collapse",
    path: "/store-manager",
    children: [
      {
        id: "store-list",
        title: <IntlMessages id="sidebar.storeManager.store" />,
        messageId: "sidebar.storeManager.store",
        type: "item",
        icon: <div style={{ marginLeft: -35, width: 1 }} />,
        path: "/store-manager/store-list",
      },
      {
        id: "distributor-list",
        title: <IntlMessages id="sidebar.storeManager.distributor" />,
        messageId: "sidebar.storeManager.distributor",
        type: "item",
        icon: <div style={{ marginLeft: -35, width: 1 }} />,
        path: "/store-manager/distributor-list",
      },
    ],
  },
  // {
  //   id: "notification",
  //   title: <IntlMessages id="sidebar.notification" />,
  //   messageId: "sidebar.notification",
  //   icon: <NotificationOutlined />,
  //   type: "collapse",
  //   path: "/notification",
  //   children: [
  //     {
  //       id: "information",
  //       title: <IntlMessages id="sidebar.notification.information" />,
  //       messageId: "sidebar.notification.information",
  //       type: "item",
  //       icon: <div style={{ marginLeft: -35, width: 1 }} />,
  //       path: "/notification/information",
  //     },
  //     {
  //       id: "condition",
  //       title: <IntlMessages id="sidebar.notification.condition" />,
  //       messageId: "sidebar.notification.condition",
  //       type: "item",
  //       icon: <div style={{ marginLeft: -35, width: 1 }} />,
  //       path: "/notification/condition",
  //     },
  //     {
  //       id: "statistical",
  //       title: <IntlMessages id="sidebar.notification.statistical" />,
  //       messageId: "sidebar.notification.statistical",
  //       type: "item",
  //       icon: <div style={{ marginLeft: -35, width: 1 }} />,
  //       path: "/notification/statistical",
  //     },
  //   ],
  // },
  {
    id: "accounts",
    title: <IntlMessages id="sidebar.accounts" />,
    messageId: "sidebar.accounts",
    icon: <UserOutlined />,
    type: "collapse",
    path: "/accounts",
    children: [
      {
        id: "list",
        title: <IntlMessages id="sidebar.accounts.list" />,
        messageId: "sidebar.accounts.list",
        type: "item",
        icon: <div style={{ marginLeft: -35, width: 1 }} />,
        path: "/accounts/list",
      },
    ],
  },
  {
    id: "system",
    title: <IntlMessages id="sidebar.system" />,
    messageId: "sidebar.system",
    icon: <SettingOutlined />,
    type: "collapse",
    path: "/system",
    children: [
      {
        id: "personal",
        title: <IntlMessages id="sidebar.system.personal" />,
        messageId: "sidebar.system.personal",
        type: "item",
        icon: <div style={{ marginLeft: -35, width: 1 }} />,
        path: "/system/personal",
      },
      {
        id: "zns",
        title: <IntlMessages id="sidebar.system.zns" />,
        messageId: "sidebar.system.zns",
        type: "item",
        icon: <div style={{ marginLeft: -35, width: 1 }} />,
        path: "/system/zns",
      },
    ],
  },
];
export default routesConfig;
